@import "/src/constants";

#loading-screen {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: $beige;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1s;

  &.slide-away {
    transform: translateY(-100vh);
  }

  #progress-bar {
    width: 300px;
    height: 40px;
    background-color: transparent;
    border: 2px dashed $dark-green;
    opacity: 0;
    transition: 0.5s opacity;

    &.visible {
      opacity: 1;
    }

    #progress-bar-fill {
      width: 1%;
      height: 100%;
      background-color: white;
      transition: 0.3s width;
    }
  }

  #enter-btn {
    position: absolute;
    letter-spacing: 2px;
    font-size: 45px;
    color: $dark-green;
    border-color: $dark-green;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    height: 58px;
    width: 185px;
    border-width: 2px;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
