#loading-screen {
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: #e2efb3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  display: flex;
  position: fixed;
}

#loading-screen.slide-away {
  transform: translateY(-100vh);
}

#loading-screen #progress-bar {
  width: 300px;
  height: 40px;
  opacity: 0;
  background-color: #0000;
  border: 2px dashed #1a4f20;
  transition: opacity .5s;
}

#loading-screen #progress-bar.visible {
  opacity: 1;
}

#loading-screen #progress-bar #progress-bar-fill {
  width: 1%;
  height: 100%;
  background-color: #fff;
  transition: width .3s;
}

#loading-screen #enter-btn {
  letter-spacing: 2px;
  color: #1a4f20;
  opacity: 0;
  visibility: hidden;
  height: 58px;
  width: 185px;
  background-color: #fff;
  border-width: 2px;
  border-color: #1a4f20;
  font-size: 45px;
  position: absolute;
}

#loading-screen #enter-btn.visible {
  visibility: visible;
  opacity: 1;
}

/*# sourceMappingURL=index.67e2fadd.css.map */
